<template>
  <v-data-table
    :headers="headers"
    v-bind="$attrs"
    v-on="$listeners"
    style="background-color: transparent"
  >
    <template #[`item.actions`]="{ item }">
      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <BaseHasPermission :permissionNames="['management.seminar.topics.delete']">
            <DeleteTopic @reload="$emit('reload')" :seminarTopicId="item.id" />
          </BaseHasPermission>
        </v-col>

        <v-col cols="auto">
          <BaseHasPermission :permissionNames="['management.seminar.topics.update']">
            <UpdateTopic @reload="$emit('reload')" :seminarTopicId="item.id" />
          </BaseHasPermission>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import DeleteTopic from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/Delete";
import UpdateTopic from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/Update";

export default {
  name: "TopicsTable",

  components: {
    DeleteTopic,
    UpdateTopic,
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.TOPICS.LIST_TABLE_HEADER_DISPLAY_NAME"),
          value: "displayName",
        },
        {
          text: this.$t("SEMINAR_MANAGEMENT.TOPICS.LIST_TABLE_HEADER_DESCRIPTION"),
          value: "description",
          sortable: false,
        },
        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
};
</script>
