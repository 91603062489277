<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.TOPICS.DELETE_DIALOG_TITLE"
    labelCancel="SEMINAR_MANAGEMENT.TOPICS.DELETE_DIALOG_LABEL_CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.TOPICS.DELETE_DIALOG_LABEL_CONFIRM"
    body="SEMINAR_MANAGEMENT.TOPICS.DELETE_DIALOG_BODY"
    tooltip="SEMINAR_MANAGEMENT.TOPICS.DELETE_DIALOG_TOOLTIP"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    @confirm="deleteTopic"
    :loading="isLoading"
    icon="far fa-trash"
  >
  </ConfirmationDialog>
</template>

<script>
import ConfirmationDialog from "@components/ConfirmationDialog/";

export default {
  name: "TopicsDelete",

  props: {
    seminarTopicId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showDialog: false,
      isLoading: false,
    };
  },

  components: {
    ConfirmationDialog,
  },

  methods: {
    async deleteTopic() {
      this.isLoading = true;
      try {
        await this.$axios.seminarManagement.deleteSeminarTopic({
          seminarTopicId: this.seminarTopicId,
        });
        this.showDialog = false;
        this.$emit("reload");
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
