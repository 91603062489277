<template>
  <BaseHasPermission :permissionNames="['management.seminar.topics.read']">
    <BaseHasPermission :permissionNames="['management.seminar.topics.create']">
      <CreateTopic @reload="listTopics" class="mb-5" />
    </BaseHasPermission>
    <TopicsTable
      @reload="listTopics"
      :items="topics"
      :options.sync="options"
      :server-items-length="meta.total"
    />
  </BaseHasPermission>
</template>

<script>
import CreateTopic from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/Create";
import TopicsTable from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/List/partials/TopicsTable";

export default {
  name: "TopicList",

  components: {
    CreateTopic,
    TopicsTable,
  },

  data() {
    return {
      topics: [],
      isLoading: true,
      options: {
        page: 1,
        perPage: 10,
      },
      meta: {
        total: 0,
      },
    };
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listTopics();
      },
    },
  },

  methods: {
    async listTopics() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.listSeminarTopics({
          ...this.options,
          perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
          seminarId: this.seminarId,
        });
        this.topics = data.data;
        this.meta = data.meta;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listTopics();
  },
};
</script>
