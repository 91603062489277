<template>
  <iframe
    :src="mapsUrl"
    :width="width"
    :height="height"
    frameborder="0"
    style="border: 0"
    allowfullscreen=""
    aria-hidden="false"
    tabindex="0"
    v-if="showMap"
  ></iframe>
</template>

<script>
export default {
  name: "GoogleMapsLocation",

  props: {
    street: {
      type: String,
      required: true,
    },

    zipCode: {
      type: String,
      required: true,
    },

    city: {
      type: String,
      required: true,
    },

    width: {
      type: String,
      required: false,
      default: "100%",
    },

    height: {
      type: String,
      required: false,
      default: "333",
    },
  },

  computed: {
    apiKey() {
      return process.env.VUE_APP_GMAPS_API_KEY || null;
    },

    mapsUrl() {
      return `https://www.google.com/maps/embed/v1/place?key=${this.apiKey}&q=${this.street}+${this.zipCode}+${this.city}`;
    },

    showMap() {
      return !!this.apiKey && !!this.street && !!this.city && !!this.zipCode;
    },
  },
};
</script>
