<template>
  <div class="mb-10">
    <SeminarDocuments :documents="seminarDocuments" v-if="hasSeminarDocuments" />
  </div>
</template>

<script>
import documentTypes from "@data/documentTypes";
import SeminarDocuments from "@components/AuthSeminar/SeminarDocuments";

export default {
  name: "Documents",

  components: {
    SeminarDocuments,
  },

  data() {
    return {
      documents: [],
      isLoading: true,
    };
  },

  props: {
    seminarId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    seminarDocuments() {
      return this.documents.filter(({ displayName }) => displayName !== documentTypes.CONTRACTS);
    },

    hasSeminarDocuments() {
      return this.seminarDocuments.length > 0;
    },
  },

  methods: {
    async listDocuments() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.listSeminarDocuments({
          page: 1,
          perPage: 0,
          sortBy: ["uploadedAt"],
          sortDesc: [true],
          seminarId: this.seminarId,
        });

        this.documents = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listDocuments();
  },
};
</script>
