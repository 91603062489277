<template>
  <BaseHasPermission :permissionNames="['management.seminar.modules.read']">
    <BaseHasPermission :permissionNames="['management.seminar.modules.create']">
      <CreateModule @reload="listModules" class="mb-5" />
    </BaseHasPermission>
    <ModulesTable
      @reload="listModules"
      :items="modules"
      :options.sync="options"
      :server-items-length="meta.total"
    />
  </BaseHasPermission>
</template>

<script>
import CreateModule from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/Create";
import ModulesTable from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/List/partials/ModulesTable";

export default {
  name: "ModuleList",

  components: {
    CreateModule,
    ModulesTable,
  },

  data() {
    return {
      modules: [],
      isLoading: true,
      options: {
        page: 1,
        perPage: 10,
      },
      meta: {
        total: 0,
      },
    };
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.listModules();
      },
    },
  },

  methods: {
    async listModules() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.listSeminarModules({
          ...this.options,
          perPage: this.options.perPage === -1 ? 0 : this.options.perPage,
          seminarId: this.seminarId,
        });
        this.modules = data.data;
        this.meta = data.meta;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.listModules();
  },
};
</script>
