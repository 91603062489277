<template>
  <ModuleSubPage fullWidth :loading="isLoading" v-if="seminar">
    <BaseHasPermission :permissionNames="['management.seminar.read']">
      <v-row>
        <v-col cols="12">
          <BaseBackButton />
        </v-col>
        <v-col cols="12">
          <SeminarFieldOfAction :seminarFieldOfAction="seminar.seminarFieldOfAction" class="mb-6" />
        </v-col>
        <v-col cols="8">
          <BaseHeadline size="2" class="mb-4">
            {{ seminar.title }}
          </BaseHeadline>

          <BaseHeadline size="3" class="mb-6" color="lowEmphasis" v-if="seminar.subTitle">
            {{ seminar.subTitle }}
          </BaseHeadline>

          <SeminarDescription :seminarDescription="seminar.description" class="mb-6" />

          <Documents :seminarId="seminar.id" />

          <Editables :seminarTypeId="seminar.seminarTypeId" />
        </v-col>

        <v-col cols="4">
          <SeminarInfoBar :seminar="seminar" />
          <LoactionMap :location="seminar.seminarVenue" />
        </v-col>
      </v-row>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import Editables from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Editables.vue";
import SeminarFieldOfAction from "@components/SeminarCardItems/SeminareFieldOfAction.vue";
import SeminarDescription from "@components/AuthSeminar/SeminarDescription.vue";
import SeminarInfoBar from "@views/Restricted/SeminarManagement/Seminars/Show/partials/SeminarInfoBar";
import LoactionMap from "@components/LocationMap";
import Documents from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Documents";

export default {
  name: "SeminarManagementShow",

  components: {
    ModuleSubPage,
    Editables,
    SeminarFieldOfAction,
    SeminarDescription,
    SeminarInfoBar,
    LoactionMap,
    Documents,
  },

  data() {
    return {
      isLoading: true,
      seminar: null,
    };
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },
  },

  methods: {
    async showSeminar() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.seminarManagement.showSeminar({
          seminarId: this.seminarId,
        });
        this.seminar = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  created() {
    this.showSeminar();
  },
};
</script>
