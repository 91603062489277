<template>
  <BaseBorderSheet class="pa-6" v-if="seminar">
    <SeminarNumber :seminarNumber="seminar.number" />
    <SeminarDate :seminar="seminar" />
    <SeminarDuration :seminar="seminar" />
    <SeminarLocation :seminar="seminar" />
    <SeminarContactPeople :seminar="seminar" />
  </BaseBorderSheet>
</template>

<script>
import SeminarDate from "@components/SeminarInfoBar/SeminarDate.vue";
import SeminarDuration from "@components/SeminarInfoBar/SeminarDuration.vue";
import SeminarLocation from "@components/SeminarInfoBar/SeminarLocation.vue";
import SeminarNumber from "@components/SeminarInfoBar/SeminarNumber.vue";
import SeminarContactPeople from "@components/SeminarInfoBar/SeminarContactPeople";

export default {
  name: "SeminarInfoBar",

  components: {
    SeminarDate,
    SeminarDuration,
    SeminarLocation,
    SeminarNumber,
    SeminarContactPeople,
  },

  props: {
    seminar: {
      type: Object,
      required: true,
    },
  },
};
</script>
