<template>
  <v-data-table
    :headers="headers"
    v-bind="$attrs"
    v-on="$listeners"
    style="background-color: transparent"
  >
    <template #[`item.startAt`]="{ item }">
      {{ dateTime(item.startAt) }}
    </template>

    <template #[`item.endAt`]="{ item }">
      {{ dateTime(item.endAt) }}
    </template>

    <template #[`item.actions`]="{ item }">
      <v-row no-gutters justify="end">
        <v-col cols="auto">
          <BaseHasPermission :permissionNames="['management.seminar.modules.delete']">
            <DeleteModule @reload="$emit('reload')" :seminarModuleId="item.id" />
          </BaseHasPermission>
        </v-col>

        <v-col cols="auto">
          <BaseHasPermission :permissionNames="['management.seminar.modules.update']">
            <UpdateModule @reload="$emit('reload')" :seminarModuleId="item.id" />
          </BaseHasPermission>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
</template>

<script>
import DeleteModule from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/Delete";
import UpdateModule from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/Update";
import dateFilter from "@mixins/dateFilter";

export default {
  name: "ModuleTable",

  mixins: [dateFilter],

  components: {
    DeleteModule,
    UpdateModule,
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("SEMINAR_MANAGEMENT.MODULES.LIST_TABLE_HEADER_DISPLAY_NAME"),
          value: "displayName",
        },
        {
          text: this.$t("SEMINAR_MANAGEMENT.MODULES.LIST_TABLE_HEADER_DESCRIPTION"),
          value: "description",
          sortable: false,
        },
        {
          text: this.$t("SEMINAR_MANAGEMENT.MODULES.LIST_TABLE_HEADER_START_AT"),
          value: "startAt",
        },
        {
          text: this.$t("SEMINAR_MANAGEMENT.MODULES.LIST_TABLE_HEADER_END_AT"),
          value: "endAt",
        },
        {
          value: "actions",
          align: "end",
          sortable: false,
        },
      ];
    },
  },
};
</script>
