<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.MODULES.CREATE_DIALOG_TITLE"
    label="SEMINAR_MANAGEMENT.MODULES.CREATE_DIALOG_LABEL_CREATE"
    labelCancel="SEMINAR_MANAGEMENT.MODULES.CREATE_DIALOG_LABEL_CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.MODULES.CREATE_DIALOG_LABEL_CONFIRM"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    @cancel="resetForm"
    :loading="isLoading"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <ModuleForm v-model="formData" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import ConfirmationDialog from "@components/ConfirmationDialog/";
import ModuleForm from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/partials/ModuleForm.vue";

export default {
  name: "ModulesCreate",

  mixins: [formValidation],

  data() {
    return {
      formData: {
        displayName: null,
        description: null,
        dateRange: [new Date(), new Date()],
      },
      showDialog: false,
      isLoading: false,
    };
  },

  components: {
    ConfirmationDialog,
    ModuleForm,
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },

    initData() {
      return { displayName: null, description: null, dateRange: [new Date(), new Date()] };
    },
  },

  methods: {
    save() {
      this.validate();

      this.$nextTick(() => {
        if (this.formValidation) {
          this.createModule();
        }
      });
    },

    async createModule() {
      try {
        await this.$axios.seminarManagement.createSeminarModule({
          displayName: this.formData.displayName,
          description: this.formData.description,
          seminarId: this.seminarId,
          startAt: this.formData.dateRange[0],
          endAt: this.formData.dateRange[1],
        });
        this.showDialog = false;
        this.$emit("reload");
        this.resetForm();
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    resetForm() {
      this.formData = this.initData;
      this.resetValidation();
    },
  },
};
</script>
