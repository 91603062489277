<template>
  <div :style="style" v-if="showMaps">
    <GoogleMapsLocation
      :street="location.street"
      :zipCode="location.zipCode"
      :city="location.city"
    />
  </div>
</template>

<script>
import GoogleMapsLocation from "./partials/GoogleMapsLocation.vue";

export default {
  name: "SeminarLocationMap",

  props: {
    location: {
      type: Object,
      required: true,
      default: () => ({
        street: null,
        city: null,
        zipCode: null,
      }),
    },

    top: {
      type: Boolean,
      required: false,
    },

    bottom: {
      type: Boolean,
      required: false,
    },

    left: {
      type: Boolean,
      required: false,
    },

    right: {
      type: Boolean,
      required: false,
    },
  },

  components: {
    GoogleMapsLocation,
  },

  computed: {
    showMaps() {
      return !!this.location;
    },

    style() {
      const border = `1px solid ${this.$vuetify.theme.themes.light.gray400}`;

      return {
        borderLeft: !this.right && border,
        borderRight: !this.left && border,
        borderTop: !this.bottom && border,
        borderBottom: !this.top && border,
      };
    },
  },
};
</script>
