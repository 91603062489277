<template>
  <ConfirmationDialog
    title="SEMINAR_MANAGEMENT.TOPICS.UPDATE_DIALOG_TITLE"
    labelCancel="SEMINAR_MANAGEMENT.TOPICS.UPDATE_DIALOG_LABEL_CANCEL"
    labelConfirm="SEMINAR_MANAGEMENT.TOPICS.UPDATE_DIALOG_LABEL_CONFIRM"
    tooltip="SEMINAR_MANAGEMENT.TOPICS.UPDATE_DIALOG_TOOLTIP"
    icon="far fa-pencil"
    v-model="showDialog"
    tertiary
    dialogWidth="640px"
    color="primary"
    @confirm="save"
    :loading="isLoading"
  >
    <v-form v-on:submit.prevent ref="form" v-model="formValidation" lazy-validation>
      <TopicForm v-model="formData" />
    </v-form>
  </ConfirmationDialog>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import ConfirmationDialog from "@components/ConfirmationDialog/";
import TopicForm from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/partials/TopicForm.vue";

export default {
  name: "TopicsUpdate",

  mixins: [formValidation],

  props: {
    seminarTopicId: {
      type: Number,
      required: true,
    },
  },

  watch: {
    showDialog(value) {
      if (!value) return;
      this.showTopic();
    },
  },

  data() {
    return {
      formData: {
        displayName: null,
        description: null,
        dateRange: [new Date(), new Date()],
      },
      showDialog: false,
      isLoading: false,
    };
  },

  components: {
    ConfirmationDialog,
    TopicForm,
  },

  computed: {
    seminarId() {
      return this.$route.params.seminarId;
    },

    initData() {
      return { displayName: null, description: null, dateRange: [new Date(), new Date()] };
    },
  },

  methods: {
    save() {
      this.validate();

      this.$nextTick(() => {
        if (this.formValidation) {
          this.updateTopic();
        }
      });
    },

    async updateTopic() {
      this.isLoading = true;

      try {
        await this.$axios.seminarManagement.updateSeminarTopic({
          ...this.formData,
          seminarTopicId: this.seminarTopicId,
          seminarId: this.seminarId,
        });
        this.showDialog = false;
        this.$emit("reload");
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    async showTopic() {
      this.isLoading = true;

      try {
        const { data } = await this.$axios.seminarManagement.showSeminarTopic({
          seminarTopicId: this.seminarTopicId,
        });
        this.formData = data.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
