<template>
  <BaseChip v-if="!!seminarFieldOfAction">
    <span>
      <BaseDot :color="color" />
    </span>
    <span>
      {{ seminarFieldOfAction.description }}
    </span>
  </BaseChip>
</template>

<script>
export default {
  name: "SeminarFieldOfAction",

  props: {
    seminarFieldOfAction: {
      type: undefined,
      required: true,
    },
  },

  computed: {
    color() {
      return this.seminarFieldOfAction.color || null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dot {
  display: inline-block;
  width: 10px;
  height: 10px;
}
</style>
