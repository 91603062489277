<template>
  <v-row>
    <v-col cols="12" class="mb-n3">
      <BaseTextField
        v-model="formData.displayName"
        label="SEMINAR_MANAGEMENT.TOPICS.FORM_LABEL_DISPLAY_NAME"
        required
      />
    </v-col>

    <v-col cols="12" class="mb-n3">
      <BaseTextField
        v-model="formData.description"
        label="SEMINAR_MANAGEMENT.TOPICS.FORM_LABEL_DESCRIPTION"
        required
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TopicForm",

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  computed: {
    formData: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
