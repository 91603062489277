<template>
  <div class="mt-5">
    <v-tabs v-model="tabs" background-color="transparent">
      <v-tab v-if="hasPermission({ permissionNames: ['management.seminar.topics.read'] })">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR.SHOW.EDITABLE_TAB_LABEL_TOPICS") }}
      </v-tab>
      <v-tab v-if="isWebSeminar">
        {{ $t("SEMINAR_MANAGEMENT.SEMINAR.SHOW.EDITABLE_TAB_LABEL_MODULES") }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs" class="py-5">
      <v-tab-item v-if="hasPermission({ permissionNames: ['management.seminar.topics.read'] })">
        <TopicList />
      </v-tab-item>

      <v-tab-item v-if="isWebSeminar">
        <ModuleList />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import TopicList from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Topics/List";
import ModuleList from "@views/Restricted/SeminarManagement/Seminars/Show/partials/Modules/List";
import SeminarType from "@data/seminarTypes";
import hasPermission from "@mixins/hasPermission";

export default {
  name: "Editables",

  mixins: [hasPermission],

  data() {
    return {
      tabs: null,
    };
  },

  props: {
    seminarTypeId: {
      type: Number,
      required: true,
    },
  },

  computed: {
    isWebSeminar() {
      return (
        this.seminarTypeId === SeminarType.WEB_SEMINAR &&
        this.hasPermission({ permissionNames: ["management.seminar.modules.read"] })
      );
    },
  },

  components: {
    TopicList,
    ModuleList,
  },
};
</script>
